.overview {
  position: relative;
  z-index: 9;
  margin: 0 15px;
  background-color: var(--cardBg);
  border-radius: 12px;
  padding: 60px 40px;
  margin-bottom: 22px;
  @media screen and (max-width: 1399px) {
    padding: 60px 20px;
  }
  @media screen and (max-width: 991px) {
    margin: 0 0 15px 0;
  }
  @media screen and (max-width: 576px) {
    padding: 20px;
  }
  .card {
    padding: 0 40px;
    @media screen and (max-width: 1399px) {
      padding: 0 10px;
    }
    @media screen and (max-width: 991px) {
      padding: 0 0 20px;
    }
    .title {
      font-size: 28px;
      font-weight: 600;
      color: var(--txt);
      margin: 0 0 5px 0;
      word-break: break-word;
      @media screen and (max-width: 1199px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        font-size: 18px;
      }
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: var(--txt);
      @media screen and (max-width: 1199px) {
        font-size: 12px;
      }
      @media screen and (max-width: 576px) {
        font-size: 12px;
      }
    }
    .icon {
        @media screen and (max-width: 576px) {
            height: 45px;
        }
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      color: var(--desc);
      @media screen and (max-width: 1199px) {
        font-size: 12px;
      }
      @media screen and (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
  .border_right {
    border-right: 1px solid var(--border);
    @media screen and (max-width: 991px) {
      border-bottom: 1px solid var(--border);
      border-right: 0;
    }
  }
}
