.dashboard {
  background: url("../../assets/images/section/bgLight.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  height: 100%;
  background-attachment: fixed;
  height: 100vh;
  line-height: normal;
  padding: 110px 20px 30px;
  color: var(--txt);
  position: relative;
  @media screen and (max-width: 1399px) {
    height: 100%;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlayBG);
    opacity: 0.85;
    z-index: 1;
  }
  .card {
    position: relative;
    z-index: 9;
    margin: 0 15px;
    background-color: var(--cardBg);
    border-radius: 12px;
    padding: 20px 40px;
    margin-bottom: 22px;
    @media screen and (max-width: 991px) {
      margin: 0 2px;
    }
    @media screen and (max-width: 576px) {
      padding: 15px;
    }
    .staked {
      display: flex;
      align-items: center;
      @media screen and (max-width: 1399px) {
        flex-direction: column;
      }
    }
    .title {
      color: var(--txt);
      font-size: 24px;
      font-weight: 700;
      span {
        color: #E09200;
      }
    }
    .title2 {
      margin: -30px 0 10px 0;
      text-align: center;
      color: #E09200;
    }
  }
  :global {
    .recharts-wrapper {
      svg {
        @media screen and (max-width: 576px) {
          height: 200px !important;
        }
      }
    }
  }
}
