.investment {
  background: url("../../assets/images/section/bgLight.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  height: 100%;
  background-attachment: fixed;
  // height: 100vh;
  line-height: normal;
  padding: 110px 20px 30px;
  color: var(--txt);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlayBG);
    opacity: 0.85;
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 9;
    padding: 0 10px;
    .title {
      color: #e09200;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
    .card {
      background-color: var(--cardBg);
      border-radius: 12px;
      padding: 20px 50px;
      .txt {
        font-size: 14px;
        font-weight: 600;
        color: var(--txt);
        margin-bottom: 5px;
        display: block;
      }
      .price {
        font-size: 26px;
        font-weight: 400;
        color: #ff000a;
        margin: 0;
      }
      .graphInfo {
        display: flex;
        align-items: center;
        gap: 5px;
        .box {
          height: 18px;
          width: 18px;
        }
        .yellowBox {
          background: linear-gradient(to bottom, #f0ce61, #b16701);
        }
        .darkGrey {
          background: #7e7e8b;
        }
        .redBox {
          background: #ff000a;
        }
        .lightGrey {
          background: #a5a6b4;
        }
        span {
          color: #7e7e8b;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
