.profileModal {
  min-width: 660px;
  .profile {
    .content {
      .title {
        color: #e09200;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }
      .card {
        background-color: var(--cardBg);
        border-radius: 12px;
        padding: 30px;
        margin: 0 15px;
        @media screen and (max-width: 991px) {
          margin: 0;
        }
        @media screen and (max-width: 576px) {
          padding: 10px;
          margin: 0;
        }
        .label {
          color: #e09200;
          font-size: 12px;
          font-weight: 500;
        }
        .profileOuter {
          position: relative;
          width: 195px;
          @media screen and (max-width: 576px) {
            width: 150px;
          }
          .profileImg {
            opacity: 1;
            display: block;
            width: 100%;
            height: auto;
            transition: 0.5s ease;
            backface-visibility: hidden;
            width: 195px;
            height: 195px;
            border: 4px solid var(--subTxt);
            border-radius: 100%;
            @media screen and (max-width: 576px) {
              width: 150px;
              height: 150px;
            }
          }
          .middle {
            transition: 0.5s ease;
            position: absolute;
            top: 30%;
            left: 20%;
            text-align: center;
            opacity: 0;
            width: 100%;
            .uploader {
              font-size: 150px;
              margin-left: -112px;
              margin-top: -80px;
              width: 192px;
              overflow: hidden;
              border-radius: 100%;
              opacity: 0;
            }
          }
        }
        .profileOuter:hover .profileImg {
          opacity: 0.4;
        }
        .profileOuter:hover .middle {
          opacity: 1;
        }
      }
    }
  }
}
