.withdrawal {
  background: url("../../assets/images/section/bgLight.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  height: 100%;
  background-attachment: fixed;
  line-height: normal;
  padding: 110px 20px 30px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlayBG);
    opacity: 0.85;
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 9;
    .title {
      color: #e09200;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
    .card {
      background-color: var(--cardBg);
      border-radius: 12px;
      padding: 60px 50px;
      margin: 0 15px;
      @media screen and (max-width: 991px) {
        margin: 0;
      }
      @media screen and (max-width: 576px) {
        padding: 10px;
        margin: 0;
      }
      .border {
        border-right: 1px solid #fff;
        @media screen and (max-width: 767px) {
          border: none;
        }
      }
      .label {
        color: #e09200;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .btn {
        background-color: #b31017;
        border: 1px solid #b31017;
        height: 40px;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 0 20px;
        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
      .listingTitle {
        color: #e09200;
      }
      .field {
        color: var(--txt);
        font-size: 14px;
        font-weight: 500;
      }
      .copyIcon {
        path {
          stroke: var(--txt);
        }
      }
    }
  }
}
