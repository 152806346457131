.footer {
  background-color: var(--sectionBg);
  color: var(--txt);
  padding: 30px 0;
  .name {
    font-size: 20px;
    font-weight: 600;
    color: var(--txt);
  }
  ul {
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 20px;
      .link {
        font-size: 14px;
        font-weight: 600;
        color: var(--txt);
        &:hover {
            opacity: 0.8;
        }
      }
    }
  }
}
