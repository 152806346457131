.login {
  .bgImg {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .rightBg {
    background-color: var(--sectionBg);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media screen and (max-width: 767px) {
      height: 100vh;
      background-image: url('../../assets/images/section/loginBg.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  
    .card {
      background-color: var(--cardBg);
      border-radius: 12px;
      padding: 20px 50px;
      height: 530px;
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid var(--cardBorder);
      @media screen and (max-width: 1199px) {
        margin: 20px;
        padding: 20px;
      }
      .logo {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 5px;
        img {
          height: 100px;
          width: 100px;
          @media screen and (max-width: 1199px) {
            height: 70px;
            width: 70px;
          }
        }
        p {
          color: var(--txt);
          font-size: 30px;
          font-weight: 600;
          @media screen and (max-width: 1199px) {
            font-size: 20px;
          }
        }
      }
      .connectBtn {
        width: 100%;
        background-color: #b31017;
        border: 1px solid #b31017;
        height: 50px;
        border-radius: 4px;
        color: var(--txt);
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
