.reinvestHistory {
    .listingTitle {
      color: #e09200;
    }
    .field {
      color: var(--txt);
      font-size: 14px;
      font-weight: 500;
    }
    .copyIcon {
      path {
        stroke: var(--txt);
      }
    }
  }
  