.contentBlock {
  background-color: var(--cardBg);
  border-radius: 5px;
  img {
    height: 290px;
    width: 100%;
    // object-fit: cover;
    border-radius: 5px;
    @media screen and (max-width: 576px) {
      height: 200px;
    }
  }
  .content {
    padding: 10px;
    h3 {
      font-size: 20px;
      font-weight: 500;
      color: var(--txt);
      margin: 0 0 10px 0;
      @media screen and (max-width: 576px) {
        font-size: 16px;
      }
    }
    p {
      color: var(--txt);
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
      line-height: 26px;
      @media screen and (max-width: 576px) {
        font-size: 12px;
      }
    }
    .actionBtn {
      width: 100%;
      background-color: #b31017;
      display: block;
      border-radius: 5px;
      text-align: center;
      padding: 15px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      @media screen and (max-width: 576px) {
        font-size: 12px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
