.PrivacyPolicy {
  .content {
    color: var(--txt);
    font-size: 16px;
    line-height: 32px;
    padding: 20px 0;
  }
  .cancelBtn {
    background-color: #b31017;
    border: 1px solid #b31017;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
  }
  .agreeBtn {
    background-color: #05af5a;
    border: 1px solid #05af5a;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
  }
}
