.payouts {
  position: relative;
  z-index: 9;
  margin: 0 15px 15px;
  background-color: var(--cardBg);
  border-radius: 12px;
  margin-bottom: 22px;
  padding: 25px;
  @media screen and (max-width: 991px) {
    width: 100%;
    margin: 0 0 15px;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #66677880;
    padding-bottom: 20px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #e09200;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 400;
    color: var(--txt);
  }
  .section {
    border-bottom: 1px solid #66677880;
  }
  .payoutVector {
    // background-image: url("../../../assets/images/section/payout.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: #ff000a;
      font-size: 24px;
      font-weight: 500;
      width: 118px;
      text-align: center;
      word-break: break-all;
      margin-right: 20px;
    }
  }
  .timer {
    position: absolute;
    right: 20%;
    top: 10px;
    @media screen and (max-width: 991px) {
      right: 40%
    }
    @media screen and (max-width: 767px) {
      right: 35%
    }
    @media screen and (max-width: 576px) {
      right: 25%
    }
    @media screen and (max-width: 400px) {
      right: 10%
    }
  }
}
