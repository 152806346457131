.reward {
  @media screen and (max-width: 576px) {
    margin-top: -70px;
  }
  .title {
    color: var(--txt);
    font-size: 24px;
    font-weight: 700;
    span {
      color: #e09200;
    }
  }
  .title2 {
    margin: 0 0 10px 0;
    text-align: center;
    color: #e09200;
  }
}
