.mainInvestors {
    position: relative;
    z-index: 9;
    margin: 0 15px 0 0;
    background-color: var(--cardBg);
    border-radius: 12px;
    margin-bottom: 22px;
    padding: 25px;
    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid #66677880;
      padding-bottom: 20px;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #e09200;
      }
    }
    .list {
        display: flex;
        gap: 13px;
        padding: 20px 0;
        border-bottom: 1px solid #252730;
        img {
            align-self: flex-start;
            height: 38px;
            width: 38px;
            border-radius: 100%;
        }
        .name {
            font-size: 14px;
            font-weight: 600;
            color: var(--txt);
            margin-bottom: 3px;
        }
        .userName {
            font-size: 12px;
            font-weight: 400;
            color: #7E7E8B;
        }
        .desc {
            font-size: 14px;
            font-weight: 400;
            color: #7E7E8B;
            margin-bottom: 5px;
        }
        .detail {
            color: #7E7E8B;
            font-size: 11px;
            font-weight: 400;
        }
    }
  }
  