.protocol {
  background: url("../../assets/images/section/bgLight.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: var(--bg);
  height: 100vh;
  background-attachment: fixed;
  line-height: normal;
  padding: 110px 20px 30px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlayBG);
    opacity: 0.85;
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 9;
    .title {
      color: #e09200;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
    .card {
      background-color: var(--cardBg);
      border-radius: 12px;
      padding: 60px 50px;
      margin: 0 15px;
      @media screen and (max-width: 991px) {
        margin: 0;
      }
      @media screen and (max-width: 576px) {
        padding: 10px;
        margin: 0;
      }
      .label{
        color: white;
        margin-bottom: 10px;
      }
    }
  }
  .chartContainer {
    .outer {
      @media screen and (max-width: 576px) {
        flex-direction: column;
      }
    }
    .btnGroup {
      display: flex;
      justify-content: space-around;
      margin-bottom: 30px;
      background-color: var(--btnGroup);
      width: 230px;
      border-radius: 5px;
      padding: 5px;
      @media screen and (max-width: 576px) {
       width: 150px;
       margin-bottom: 10px;
      }
      button {
        background: none;
        border: 1px solid transparent;
        padding: 8px 15px;
        border-radius: 5px;
        color: #bbbdc0;
        font-size: 12px;
        font-weight: 500;
        @media screen and (max-width: 576px) {
          padding: 5px;
        }
      }
      .active {
        background-color: #ffa500;
        border-color: #ffa500;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
      }
    }
    .btns {
      width: 140px;
    }
  }
}
