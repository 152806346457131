body {
  font-size: 16px;
  line-height: normal;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bg);
}
html,
body {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
.app,
.app * {
  cursor: none;
}
button,
input,
textarea,
select,
a,
.ant-modal-content,
.ant-modal-wrap {
  cursor: none;
}
img {
  max-width: 100%;
}
p {
  margin: 0;
}
.text-white {
  color: #fff;
}
button:hover {
  opacity: 0.8;
}
.h-vh {
  height: 100vh;
}
.ant-layout-sider {
  min-width: 245px !important;
  max-width: 245px !important;
  width: 245px !important;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider {
  min-width: 80px !important;
  max-width: 80px !important;
  width: 80px !important;
}
.ant-layout-sider-children {
  background-color: var(--bg);
}
.ant-layout-header {
  height: 76px;
  background-color: var(--bg) !important;
  border-bottom: 1px solid #6667784d;
  padding: 10px;
  line-height: normal;
}
.ant-modal .ant-modal-content {
  background-color: var(--modalBg);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollBg);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--modalBg);
}
.value-label {
  color: red;
}
.label {
  font-size: 12px;
  font-weight: 500;
  color: var(--txt);
  margin-bottom: 5px;
}
.baseInput {
  width: 500px;
  padding: 14px 20px 14px 20px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--input);
  border: 1px solid #62636a;
  outline: 0;
  color: var(--txt);
}

.baseInput::placeholder {
  font-size: 10px;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove the up and down arrows in Internet Explorer 10+ */
input[type="number"] {
  -ms-appearance: none;
  margin: 0;
}
.btn {
  height: 40px;
  width: 500px;
  background-color: #b31017;
  border: 1px solid #b31017;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}
.ant-layout {
  background: transparent;
}

.cursor-style {
  background-image: url("../images/icons/cursor.svg");
  background-size: contain;
  height: 1.3rem;
  width: 1.3rem;
  position: fixed;
  z-index: 99999;
  pointer-events: none;
}
.highcharts-credits {
  display: none;
}
.tooltipOuter {
  background-color: #252730;
  color: white;
  border-radius: 100%;
  padding: 5px;
  height: 60px;
  width: 60px;
  text-align: center;
}
.tooltipTxt {
  display: block;
  font-size: 14px;
}
.tooltipName {
  font-size: 10px;
  color: #7e7e8b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider {
    width: auto !important;
    max-width: auto !important;
    min-width: auto !important;
  }
  .baseInput,
  .btn {
    width: 100%;
  }
  /* .ant-layout {
    margin-left: 98px !important;
  } */
}
@media screen and (max-width: 767px) {
  .tooltipOuter {
    display: none !important;
  }
  /* .tooltipTxt {
    font-size: 12px;
  }
  .tooltipName {
    font-size: 6px; 
  } */
}
.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: linear-gradient(#b31017, #4d070a);
}
.ant-switch,
.ant-switch:hover:not(.ant-switch-disabled) {
  background-color: var(--subTxt);
}
.ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
  border: 1px solid var(--txt);
}
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: #e09200 !important;
}
.ant-checkbox-wrapper-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e09200 !important;
  border: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
  padding: 15px;
  color: var(--txt);
  text-align: center;
}

tr:nth-child(even) {
  background-color: var(--overlayBG);
}

.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #b31017;
  padding: 10px;
  color: #fff;
  border-radius: 6px 6px 0 0;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #fff;
}
.ant-tabs-tab-btn {
  color: var(--txt);
  font-size: 16px;
  font-weight: 600;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid var(--txt);
}
.highcharts-container {
  position: inherit !important;
}
.ant-modal-header {
  background-color: var(--modalBg) !important;
}
.ant-modal-title {
  color: var(--txt) !important;
  font-size: 26px !important;
}
.anticon.anticon-close.ant-modal-close-icon svg path {
  /* fill: var(--txt) !important; */
  display: none;
}