.landingHeader {
  height: 76px;
  position: fixed;
  z-index: 99;
  background-color: var(--sectionBg);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .logo {
    height: 20px;
  }
  .logoName {
    font-size: 16px;
    font-weight: 600;
    color: var(--txt);
    text-decoration: none;
    @media screen and (max-width: 576px) {
      font-size: 12px;
    }
  }
  .toggleBtn {
    background-color: #b31017;
    border: 1px solid #b31017;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    position: absolute;
    left: -13px;
    z-index: 999;
    svg {
      fill: #fff;
      font-size: 22px;
      font-weight: 800;
    }
  }
  .user {
    padding-right: 10px;
    .userImg {
      height: 34px;
      width: 34px;
      border-radius: 100%;
    }
    .userInfo {
      span {
        font-size: 12px;
        font-weight: 400;
        color: #a5a6b4;
        display: block;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: var(--txt);
        margin: 0;
      }
    }
  }
  .loginBtn {
    background-color: #05af5a;
    border: 1px solid #05af5a;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    height: 40px;
    padding: 0 40px;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
  .walletOuter {
    .walletBtn {
      background-color: #b31017;
      border: 1px solid #b31017;
      border-radius: 5px;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 18px 20px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        padding: 10px;
      }
      @media screen and (max-width: 576px) {
        height: 30px;
      }
      span {
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
    }
    .dashboardBtn {
      background-color: #E09200;
      width: 160px;
      height: 40px;
      border-radius: 5px;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        width: auto;
        padding: 10px;
      }
      @media screen and (max-width: 576px) {
        height: 30px;
      }
      span {
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
 
  .toggleMode {
    background-color: transparent;
    padding: 5px 10px;
    border: none;
    @media screen  and (max-width: 576px){
      padding: 0;
    }
    .sunIcon {
      color: #fff;
      font-size: 20px;
    }
  }
  :global {
    .ant-input {
      height: 38px;
      width: 322px;
      border: 2.5px solid var(--inputBg);
      background: var(--inputBg);
      color: var(--txt);
    }
    .ant-input::placeholder {
      color: #7e7e8b !important;
    }
    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: #b31017;
      border: 1px solid #b31017;
    }
  }
}
