.mainInvestment {
  position: relative;
  z-index: 9;
  margin: 0 15px;
  background-color: var(--cardBg);
  border-radius: 12px;
  margin-bottom: 22px;
  padding: 25px 25px 15px 25px;
  @media screen and (max-width: 991px) {
    margin: 0 0 15px 0;
  }
  @media screen and (max-width: 576px) {
    padding: 10px;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #66677880;
    padding-bottom: 20px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #e09200;
    }
  }
  .txt {
    font-size: 14px;
    font-weight: 600;
    color: var(--txt);
    margin-bottom: 5px;
    display: block;
  }
  .price {
    font-size: 26px;
    font-weight: 400;
    color: #ff000a;
    margin: 0;
  }
  .graphInfo {
    display: flex;
    align-items: center;
    gap: 5px;
    .box {
      height: 12px;
      width: 12px;
    }
    .yellowBox {
      background: linear-gradient(to bottom, #f0ce61, #b16701);
    }
    .darkGrey {
      background: #7e7e8b;
    }
    .redBox {
      background: #ff000a;
    }
    .lightGrey {
      background: #a5a6b4;
    }
    span {
      color: #7e7e8b;
      font-size: 11px;
      font-weight: 400;
    }
  }
  .chartOuter {
    @media screen and (max-width: 400px) {
      margin-top: -80px;
    }
  }
  .mgTop {
    @media screen and (max-width: 400px) {
      margin-top: -80px;
    }
  }
}
.projectList {
  padding: 10px 0;
  height: 60px;
  overflow-y: auto;
}
