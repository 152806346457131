.connectModal {
  width: 720px !important;
  .title {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    color: var(--txt);
  }
  .desc {
    color: #7e7e8b;
    font-size: 14px;
    font-weight: 400;
  }
  .walletBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--sectionBg);
    padding: 20px 0;
    border-radius: 10px;
    height: 110px;
    border: 3px solid var(--sectionBg);
    img {
      transition: transform 0.3s ease;
    }
    &:hover {
      border: 3px solid var(--border400);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      cursor: none;
    }
    &:hover img {
      transform: scale(1.2);
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: var(--txt);
      margin-top: 5px;
    }
  }
  .connectBtn {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: #b31017;
    border: 1px solid #b31017;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin: 10px 0 15px 0;
  }
  :global {
    .ant-modal {
      min-width: 720px !important;
    }
    .anticon.anticon-close.ant-modal-close-icon {
      svg {
        color: var(--txt);
      }
    }
  }
}
