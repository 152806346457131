.dark-theme {
  --bg: #181923;
  --txt: #fff;
  --subTxt: #7e7e8b;
  --inputBg: #25273080;
  --overlayBG: #2A2C3C;
  --cardBg: rgba(0, 0, 0, 0.6);
  --desc: #7C8DB5;
  --border: #E6EDFF;
  --modalBg: #252630;
  --sectionBg: #181923;
  --border400: #666778;
  --scrollBg: #393d4b;
  --tooltipBg: #252730;
  --input: #1e202a;
  --btnGroup: #24252d;
  --cardBorder: #9697A0;
}
.light-theme {
  --bg: #F3F3F3;
  --txt: #000;
  --subTxt: #7e7e8b;
  --inputBg: #f5f5f5;
  --overlayBG: #e8e8e7;
  --cardBg: #F8F8F8;
  --desc: #000;
  --border: #000;
  --modalBg: #fff;
  --sectionBg: #f5f5f5;
  --border400: #ccc;
  --scrollBg: #f2f2f2;
  --tooltipBg: #dedcdc;
  --input: #f5f5f5;
  --btnGroup: #fff;
  --cardBorder: #000;
}
