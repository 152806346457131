.investment {
    background: url("../../assets/images/section/bgLight.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-color: var(--bg);
    // height: 100%;
    background-attachment: fixed;
    height: 100vh;
    line-height: normal;
    padding: 110px 20px 30px;
    color: var(--txt);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--overlayBG);
      opacity: 0.85;
      z-index: 1;
    }
    .content {
      position: relative;
      z-index: 9;
      padding: 0 10px;
      .title {
        color: #e09200;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }
      .card {
        background-color: var(--cardBg);
        border-radius: 12px;
        padding: 20px 50px;
      }
    }
  }
  