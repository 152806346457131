.sidebar {
  height: 100vh;
  overflow: auto;
  padding: 20px 0;
  border-right: 0.5px solid #6667784d;
  .logo {
    border-bottom: 0.5px solid #6667784d;
    
  }
  .name {
    font-size: 14px;
    font-weight: 600;
    color: var(--txt);
  }
  .mode {
    color: var(--subTxt);
    font-size: 12px;
    font-weight: 400;
  }
  .lastChild {
    background-color: transparent !important;
    color: var(--subTxt) !important;
    font-size: 12px;
    font-weight: 400;
  }
  .switch {
    margin-left: 36px;
  }
  :global {
    .ant-menu {
      background-color: var(--bg);
      margin-top: 20px;
      .ant-menu-item {
        margin-bottom: 20px;
      }
    }
    .ant-menu-title-content {
      color: var(--subTxt);
      font-size: 12px;
      font-weight: 400;
    }
    .ant-menu-light .ant-menu-item-selected,
    .ant-menu-light > .ant-menu .ant-menu-item-selected {
      background-color: #b31017;
    }
    .ant-menu-item.ant-menu-item-selected {
      svg {
        path {
          fill: #fff;
          stroke: #fff;
        }
      }
      
      .ant-menu-title-content {
        color: #fff;
        font-weight: 600;
      }
    }
    // .ant-menu-item.ant-menu-item-selected:last-child {
    //   svg {
    //     path {
    //       fill: #7e7e8b;
    //       stroke: #7e7e8b;
    //     }
    //   }
      
    //   .ant-menu-title-content {
    //     color: #7e7e8b;
    //     font-weight: 600;
    //   }
    // }
    .ant-switch.ant-switch-checked {
      background-color: #b31017;
    }
    .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
      background-color: #b31017;
    }
    .ant-switch {
      background-color: #d9d9d9;
    }
    .ant-switch:hover:not(.ant-switch-disabled) {
      background-color: #d9d9d9;
    }
  }
}
